import { BlogPostDisplay } from '@/BlogPostDisplay';
import { CommonHero } from '@/CommonHero';
import { Seo } from '@/layout/Seo';
import { Pagination } from '@/Pagination';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const PostsContainer = styled.div`
    padding: 32px 16px;

    @media (min-width: 1024px) {
        padding: 64px 32px;
    }
`;

type BlogPageProps = {
    data: {
        allMdx: {
            nodes: {
                frontmatter: {
                    title: string;
                    description: string;
                    published: string;
                    cover: { childImageSharp: ImageDataLike };
                };
                excerpt: string;
                slug: string;
            }[];
        };
        cover: { childImageSharp: ImageDataLike };
    };
    pageContext: {
        currentPage: number;
        limit: number;
        numPages: number;
        skip: number;
    };
};

const BlogPage = ({ data, pageContext }: BlogPageProps) => {
    const { currentPage, numPages } = pageContext;

    const allBlogPosts = data.allMdx.nodes;

    return (
        <Fragment>
            <Seo
                title="Our Blog - Beauty Religion"
                description="Our blog offers wide range of articles related to cosmetic treatments that we provide in west hollywood and dana point."
            />
            <CommonHero />
            <PostsContainer>
                {allBlogPosts.map((post, i) => (
                    <BlogPostDisplay
                        key={i}
                        title={post.frontmatter.title}
                        slug={`/blog/${post.slug}`}
                        date={post.frontmatter.published}
                        excerpt={post.frontmatter.description || post.excerpt}
                        image={post.frontmatter.cover.childImageSharp}
                    />
                ))}
            </PostsContainer>
            {numPages > 1 ? <Pagination currentPage={currentPage} numPages={numPages} /> : ''}
        </Fragment>
    );
};

export default BlogPage;

export const query = graphql`
    query BlogPage($limit: Int!, $skip: Int!) {
        allMdx(
            filter: { fileAbsolutePath: { regex: "/blog/" } }
            sort: { fields: frontmatter___published, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                slug
                excerpt(pruneLength: 123)
                frontmatter {
                    title
                    description
                    published(formatString: "DD MMMM YYYY")
                    cover {
                        childImageSharp {
                            gatsbyImageData(width: 471, height: 240)
                        }
                    }
                }
            }
        }
    }
`;
