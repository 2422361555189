import CLink from '@/CLink';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from 'gatsby-plugin-image';
import { flexSpace } from './layout/styles/classes';

const Post = styled.div`
    max-width: 820px;
    margin: 0 auto;

    :not(:last-of-type) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    > .gatsby-image-wrapper {
        margin-bottom: 32px;
    }

    @media (min-width: 768px) {
        ${flexSpace};
        max-width: 1200px;

        > .gatsby-image-wrapper {
            margin-bottom: 0;
            margin-right: 32px;
        }

        > div:not(.gatsby-image-wrapper) {
            width: 70%;
        }
    }

    @media (min-width: 1024px) {
        > .gatsby-image-wrapper {
            flex-shrink: 0;
        }
    }
`;

const Title = styled(CLink)`
    > h5 {
        transition: color 0.3s ease-in-out;
        margin: 0 0 16px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.pink1};
    }

    :hover,
    :focus-visible {
        > h5 {
            color: ${({ theme }) => theme.colors.pink2};
        }
    }
`;

const Date = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.colors.gray2};
`;

const Excerpt = styled.p`
    margin: 16px 0;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
`;

const Link = styled(CLink)`
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green2};
    transition: color 0.3s ease-in-out;

    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.green3};
    }
`;

type PostProps = {
    title: string;
    slug: string;
    date: string;
    excerpt: string;
    image?: ImageDataLike;
};

export const BlogPostDisplay = ({ title, slug, date, excerpt, image }: PostProps) => {
    return (
        <Post>
            {image ? <GatsbyImage image={getImage(image) as IGatsbyImageData} alt="title" /> : ''}
            <div>
                <Title to={slug}>
                    <h5>{title}</h5>
                </Title>

                <Date
                    css={css`
                        display: none;

                        @media (min-width: 768px) {
                            display: block;
                        }
                    `}
                >
                    {date}
                </Date>

                <Excerpt>{excerpt}</Excerpt>

                <Link to={slug}>Read More</Link>
            </div>
        </Post>
    );
};
